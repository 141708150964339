import React from "react"
import { Helmet } from "react-helmet";
import { graphql } from 'gatsby'
import Layout from "../components/layout";
import SEO from "../components/seo";
import Breadcrumbs_Insights from "../components/Breadcrumbs/Breadcrumbs_Insights"
import ArticleBlock from "../components/ArticleBlock/ArticleBlock"
import NewsLetter from "../components/NewsLetter/NewsLetter"
import FilterBlock from "../components/FilterBlock/FilterBlock"
//import {InsightsDetails} from "../queries/common_use_query"
//import LoaderLogo from '../images/loader_logo_new.svg';
import "../scss/custom.scss"

function InsightsTemplate (props){

    var mypagedata = props.data.glstrapi?.insights;

    var data = mypagedata[0]
    

    return (
    <>
      <Layout Layout={'Without_Banner'} classNames="insight_det Without_Banner"  Pagename={props.GQLPage?.Pagename}>
        <SEO title={data && data.Meta_Title} description={data && data.Meta_Description} image={data && data?.Banner_Image?.url} />

        <Helmet/>

        {
          data && <>

              <div className="page-content static-page">
                <Breadcrumbs_Insights page_name={data.Category} Heading={data.Title} location={props.location}/>

                <ArticleBlock article_data = {data} />

                <NewsLetter /> 
              </div>

              <FilterBlock insights_id={''} />
          </>
        }

      </Layout>
    </>
  )
}

export default InsightsTemplate

export const pageQuery = graphql`
query InsightsDeatils($url: String!){
  glstrapi {
    insights(where:{URL:$url}){
        id
        Title
        Meta_Title
        Meta_Description
        URL
        Category
        Date
        Tag
        Banner_Image{
          url
          alternativeText
        }
        imagetransforms

        Author
        Highlight_Content
        Embed_Code
        Embed_Video_URL
        Content
        Show_Newsletter
        Office{
          id
          Name
          Direct_Phone
          Image{
            url
            alternativeText
          }      
        }
        people{
            id
            Name
            URL
            Phone
            Tile_Image{
              url
              alternativeText
            }      
          }
    }
  }
}`
