import React, { useEffect, useState, useRef } from "react";
import HTMLReactParser from 'html-react-parser';

import { useStaticQuery, graphql } from "gatsby"
import { Link } from "@StarberryUtils";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share"

import dateFormat from 'dateformat';
import { Container, Row, Col } from 'react-bootstrap';
// import ImgPerson from "../../images/person.jpg"
// import ArticleImgLarge from "../../images/static/article.jpg"
// import ArticleImgMedium from "../../images/static/article-t.jpg"
// import ArticleImgSmall from "../../images/static/article-m.jpg"
// import ScrollAnimation from 'react-animate-on-scroll';

import { ImageModule } from "../../modules/Image_Module";
import bgCurve2 from "../../images/home/pg-logo-curve-2.png"

import ReactPlayer from 'react-player/lazy'
import { youtube_parser } from "../common/utils";
import { Helmet } from "react-helmet"

// Images
import './ArticleBlock.scss';

const ArticleBlock = (props) => {

  let shareurl = '';
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : '';
  }

  const [Shareicons, setShareicons] = useState(false);
  const [isPlay,setPlay] = useState(false);
  const [showPopup,setShowPopup] = useState(true);
  const myref = useRef();
  
  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }

  const trackerShare = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' +shareurl,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }

  //console.log("article_data", props.article_data);


  const data = useStaticQuery(graphql`
    query InsightNeedHelpQuery {

      glstrapi {
        globalModule {
          Insights_Block {
            Need_Help_Content
            Need_Help_Title
          }
        }
      }
    }
  `)

  var global_data = data.glstrapi.globalModule.Insights_Block;

  let processedImages = JSON.stringify({});
  if (props.article_data && props.article_data.imagetransforms && props.article_data.imagetransforms.Banner_Image_Transforms) {
      processedImages = props.article_data.imagetransforms.Banner_Image_Transforms;
  }

  var youtube_url = props.article_data && props.article_data.Embed_Video_URL && youtube_parser(props.article_data.Embed_Video_URL);
  var video_url = "https://www.youtube-nocookie.com/embed/"+youtube_url
  
  let dubaiNumber = props?.article_data?.Office?.Direct_Phone;
  let dubaiContact = props?.article_data?.people?.Phone
  if(props?.article_data?.Tag == "Dubai"){
    dubaiNumber = "+971 55 152 0502";
    dubaiContact = "+971 55 152 0502";
  }

  return (
    <div className="article-section">

      <Helmet>
  <script type="application/ld+json">{`{
 "@context": "http://schema.org",
 "@type": "Article",
 "mainEntityOfPage": {
 "@type": "WebPage",
 "@id": "${shareurl}"
 },
 "headline": "${props.article_data.Title}",
 "image":{
 "@type":"ImageObject",
 "url":"https://www.thepearlgates.com${bgCurve2}"
 },
 "datePublished": "${props.article_data.Date}",
 "dateModified": "${props.article_data.Date}",
 "author": {
 "@type": "Person",
 "name": "${props.article_data.people?.Name}"
 },
 "publisher": {
 "@type": "Organization",
 "name": "The Pearl Gates",
 "logo": {
 "@type": "ImageObject",
 "url": "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
 }
 },
 "description": "${props.article_data.Meta_Description}"

}`}</script>
 </Helmet>

      <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
      <Container>
        <Row>
          {props.article_data &&
            <Col lg="12">

                <h1>{props.article_data.Title}</h1>
                <div className="article-section-author">
                  {
                    props.article_data.people && 
                    <div className="negotiator d-flex justify-content-between">
                      <figure>
                        <Link to={`/about/our-people/${props.article_data.people.URL}`}><ImageModule ImageSrc={props.article_data.people.Tile_Image} /></Link>
                      </figure>
                      <div className="info">
                        <strong className="name d-block"><Link to={`/about/our-people/${props.article_data.people.URL}`} rel="author">{props.article_data.people.Name}</Link></strong>
                        <div className="contact-nav">
                          <a href={`tel:${dubaiContact}`}>{dubaiContact}</a>  ·  {props.article_data.Date && dateFormat(props.article_data.Date, "dd/mm/yyyy")}
                        </div>
                      </div>
                    </div>
                  }
                  
                  <div className="share-row">
                    <a href="javascript:;" onClick={()=>setShareicons(!Shareicons)} onMouseLeave={closeShareicons}> <i className="icon-share"></i> <span>Share</span></a>
                    {Shareicons &&
                      <div onMouseEnter={openShareicons} onMouseLeave={closeShareicons} className="property-share-social-icons mb_dev">
                        <FacebookShareButton onClick={()=>trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                          <FacebookIcon size={32} round={false} borderRadius={`10`} />
                        </FacebookShareButton>
                        <TwitterShareButton onClick={()=>trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                          <TwitterIcon size={32} round={false} borderRadius={`10`} />
                        </TwitterShareButton>
                        <LinkedinShareButton onClick={()=>trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                          <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                        </LinkedinShareButton>
                        <WhatsappShareButton onClick={()=>trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                          <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                        </WhatsappShareButton>
                      </div>
                    }

                  </div>
                </div>
              
               <div className="animated">
                <div className="article-section-img">
                  {
                    isPlay ? <>
                      {props.article_data.Embed_Video_URL && 
                      <ReactPlayer 
                        url={video_url} 
                        playing={true} 
                        controls={true} 
                        width="100%" 
                        className="video_cls"
                        //onPause={() => setPlay(false)}
                        //onEnded={() => setPlay(false)}
                      />}
                    </> : <>
                      <picture>
                        <ImageModule ImageSrc={props.article_data.Banner_Image} imagename={"insights.Banner_Image.details"} article_id={props.article_data.id} imagetransforms={processedImages} />

                        {props.article_data.Embed_Video_URL && 
                          <strong className="play-btn-icon" onClick = { e => {setPlay(true)}}>
                            <i className="icon-play-btn"></i>
                          </strong>
                        }
                        
                      </picture>
                    </>
                  }
                  

                  
                </div>
                
               </div>
               <div className="animated" afterAnimatedIn={()=>setShowPopup(true)}>
                <div className="article-section-content padd-article-section" ref={myref}>
                  {props.article_data.Highlight_Content && <p className="highlight-text">{props.article_data.Highlight_Content}
                  </p>}
                  
                  {props.article_data.Embed_Code && 
                    
                      props.article_data.Embed_Code.includes("public.tableau.com") ? 
                        <>
                        {props.article_data.Content && HTMLReactParser(props.article_data.Content)}
                        <div className="pub_embed_code">
                          <iframe width="100%" height="580px" title="Data Visualization" scrolling="yes" allowtransparency="true" allowfullscreen="true" src={`${props.article_data.Embed_Code}&:embed=y&:showVizHome=no&:host_url=https%3A%2F%2Fpublic.tableau.com%2F&:embed_code_version=3&:tabs=no&:toolbar=no&:animate_transition=no&:display_static_image=no&:display_spinner=no&:display_overlay=yes&:display_count=yes&:language=en-US&:loadOrderID=0`} frameborder="0"></iframe>
                        </div>
                        </>
                      : <>
                        { props.article_data.Embed_Code && 
                          <div className="pub_embed_code research_cnt">
                            { HTMLReactParser(props.article_data.Embed_Code)}
                          </div>
                        }
                        {props.article_data.Content && HTMLReactParser(props.article_data.Content)}
                      </>
                  }
                  

                  
                </div>
               </div>
            </Col>
          }

        </Row>
      </Container>
      
      {/* {
        props.article_data && (

          showPopup &&           
          <div className="need_help_cnt">
              <a href="javascript:;" onClick={() => setShowPopup(false)} className="close_btn">x</a>
              <h4>{global_data.Need_Help_Title}</h4>
              <p>{global_data.Need_Help_Content}</p>

              <div className="negotiator d-flex justify-content-between">
              {
                  props.article_data.Office && <>
                      <figure>
                          {props.article_data.Office.Image && <ImageModule ImageSrc={props.article_data.Office.Image}/>}
                      </figure>
                      <div className="info">
                          <strong className="name d-block"><Link to={`/contact-real-estate-agents-in-qatar`}>{props.article_data.Office.Name}</Link></strong>
                          <div className="contact-nav">
                              <div className="mob_whatsapp">
                                  <a href={`tel:${dubaiNumber}`}>{dubaiNumber}</a>
                              </div>
                              
                          </div>
                      </div>
                  </>
              }
              </div>
          </div>
        )
      } */}
      
    </div>

  )
}



export default ArticleBlock